<template>
  <div class="filters">
    <p class="d-block w-100 text-gray-600 mb-2 ms-3">{{ $t("Filters") }}</p>
    <div class="card dashboard-filter-card" id="dashboard-filter-card">
      <!--    <div class="d-lg-flex flex-wrap flex-row align-items-center justify-content-between gap-4 card pb-50">-->
      <!--      <div class="d-lg-flex flex-wrap flex-row align-items-center justify-content-between gap-4 card"-->
      <!--         :class="selected ? 'pb-50' : ''" >-->
      <el-form
        ref="dashboardFilter"
        :model="filterData"
        :rules="dashboardFilterRules">
        <div class="dashboard-filter">
          <div class="filter-item" :class="{'is-all-selected': idSelectedAllBank}">
            <label class="dashboard-filter__label">{{ $t("Bank(s)") }}</label>
            <el-form-item prop="bank_ids">
              <div class="cursor-pointer position-relative z-index-1">
                <div @click="dialogBanksVisible = true" class="select-popup"></div>
                <div class="is-all-selected__text">{{ $t('All') }}</div>
                <el-select
                    multiple
                    class="position-relative"
                    v-model="filterData.bank_ids"
                    :placeholder="$t('All Banks')"
                    collapse-tags
                    disabled
                    style="z-index: -1"
                    @click="dialogBanksVisible = true"
                >
                  <el-option
                    v-for="(item,index) in banks"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
          </div>
          <div class="filter-item" :class="{'is-all-selected': idSelectedAllCountries}">
            <label class="dashboard-filter__label">{{ $t("Countries") }}</label>
            <el-form-item prop="countries">
              <div class="cursor-pointer position-relative z-index-1">
                <div @click="dialogCountriesVisible = true" class="select-popup"></div>
                <div class="is-all-selected__text">{{ $t('All') }}</div>
                <el-select
                    multiple
                    class="position-relative"
                    v-model="filterData.countries"
                    :placeholder="$t('All Countries')"
                    collapse-tags
                    disabled
                    style="z-index: -1"
                    @click="dialogCountriesVisible = true"
                >
                  <el-option
                    v-for="(item,index) in countries"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
          </div>
          <div class="filter-item">
            <label class="dashboard-filter__label">{{ $t("Time Period") }}</label>
            <el-form-item>
              <el-select
                  v-model="filterData.time_period"
                  :placeholder="$t('Time Period')"
              >
                <el-option
                    v-for="(item,index) in timePeriods"
                    :key="index"
                    :label="item.title"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div
              class="btn main-btn px-15 mt-3 apply-filter"
              @click="applyFilters">{{ $t("Apply") }}
          </div>
        </div>
      </el-form>
    </div>

    <SelectModal
      v-if="dialogBanksVisible"
      :title="$t('Select Banks')"
      :subtitle="$t('Select Bank you want to filter on')"
      :placeholder="$t('Search Banks')"
      :popupVisible="dialogBanksVisible"
      :dataForSelect="banks"
      :dataForUpdate="filterData.bank_ids"
      :multiple="true"
      @handle-close="dialogBanksVisible = false"
      @handle-confirm="handleConfirmBank"
    />

    <SelectModal
      v-if="dialogCountriesVisible"
      :title="$t('Select Country')"
      :subtitle="$t('Select Country you want to filter on')"
      :placeholder="$t('Search Country')"
      :popupVisible="dialogCountriesVisible"
      :dataForSelect="countries"
      :dataForUpdate="filterData.countries"
      :multiple="true"
      :selfValue="true"
      @handle-close="dialogCountriesVisible = false"
      @handle-confirm="handleConfirmCountry"
    />
  </div>
</template>
<script>
import SelectModal from "@/buying-teams/shared-components/modals/SelectModal";

export default {
  name: "DashboardFilter",

  components: {
    SelectModal,
  },
  data() {
    return {
      dashboardFilterRules: {
        bank_ids: [
          { required: true, message: this.$t("Please select bank"), trigger: "change" }
        ],
        countries: [
          { required: true, message: this.$t("Please select countries"), trigger: "change" }
        ],
      },
      dialogBanksVisible: false,
      dialogCountriesVisible: false
    }
  },
  props: {
    banks: Array,
    countries: Array,
    timePeriods: Array,
    filterData: Object
  },

  emits: ["apply-filters"],

  computed: {
    idSelectedAllBank() {
      return this.filterData.bank_ids.length === this.banks.length && this.banks.length !== 0
    },
    idSelectedAllCountries() {
      return this.filterData.countries.length === this.countries.length && this.countries.length !== 0
    }
  },

  methods: {
    applyFilters() {
      this.$refs.dashboardFilter.validate(async valid => {
        if (valid) {
          this.$emit("apply-filters", this.filterData);
        }
      })
    },
    handleConfirmBank(event) {
      this.filterData.bank_ids = event;
      this.dialogBanksVisible = false;
    },
    handleConfirmCountry(event) {
      this.filterData.countries = event;
      this.dialogCountriesVisible = false;
    },
  }
};
</script>
<style lang="scss">

</style>
